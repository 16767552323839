/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

exports.onRouteUpdate = function({ location }) {
	// Don't track while developing.
	if (process.env.NODE_ENV === `production`){
		if(twq && typeof twq === `function`) {
			twq(`track`, `PageView`);
		}
		if(fbq && typeof fbq === `function`){
			fbq(`track`, `ViewContent`)
		}
	}
};
